<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">nav with apartment name</div>
        <div class="module-preview--contents">
            <span class="font-inter font-600 text-lg">{{ getValue('name') }}</span>            
            <span class="text-right text-sm">{{ getValue('phoneNumber') }}</span>
            <span class="font-inter font-500 text-base">{{ getValue('tagLine') }}</span>
            <span class="text-right text-sm">{{ getValue('email') }}</span>
        </div>
    </div>    
</template>

<script>
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ]
    }
</script>
<style scoped>    
    .module-preview--contents{
        display:flex;
        align-items:center;
        flex-wrap: wrap;
        text-align: left;
        justify-content: space-between;
        grid-gap: unset;
    }
    .module-preview--contents > span{
        flex: 1 0 50%;
    }
</style>